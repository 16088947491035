'use client'

import { ConnectWalletScreen, DashboardPage, Header } from "../components";
import { FunctionalProvider } from "../components/providers";
import { WithCSR } from "../components/ui";
import { registerRaleonEvent, useUnlockdSession } from "../logic";
import { SubHeader } from "../components/commons/dashboard/SubHeader";
import { useEffect } from "react";

export default function Dashboard(): JSX.Element {
  const { isWalletConnected, walletAddress } = useUnlockdSession();
  useEffect(() => {
    if (isWalletConnected) {
      registerRaleonEvent("on-dashboard-page", walletAddress);
    }
  }, [isWalletConnected]);
  return (
    <WithCSR>
      <FunctionalProvider>
        <Header />
        <main className="relative overflow-hidden px-28 pb-20">
          <SubHeader />
          {isWalletConnected ? (
            <DashboardPage />
          ) : (
            <ConnectWalletScreen className="mt-14 my-10 mx-auto" />
          )}
        </main>
      </FunctionalProvider>
    </WithCSR>
  );
}
